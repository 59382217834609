import React, { createContext, useState, useContext, useEffect } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import { Redirect } from "react-router";

const INITIAL_CONTEXT = {
  signed: false,
  loading: true,
  isAdmin: false,
  user: {},
  SignIn: () => {},
  SignOut: () => {},
};

const AuthContext = createContext(INITIAL_CONTEXT);

export default function AuthProvider({ children }) {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signed, setSigned] = useState(false);
  const [user, setUser] = useState();

  useEffect(() => {
    async function loadStorage() {
      const storageToken = localStorage.getItem("@interbrasil:token");
      const userLoaded = localStorage.getItem("@interbrasil:user");

      setUser(JSON.parse(userLoaded));
      if (storageToken) {
        api.defaults.headers.common["Authorization"] = `Bearer ${storageToken}`;
        setSigned(true);
        console.log("signed ===> true");
      }
    }
    loadStorage();
    setLoading(false);
  }, []);

  async function SignIn(data) {
    setLoading(true);
    api
      .post("/auth/local", data)
      .then((res) => {
        if (res.data.user.tipo_usuario.nome === "Admin") {
          setIsAdmin(true);
        }
        if (res.data.user.tipo_usuario.nome === "Corretor") {
          toast.error(
            "Corretores não tem acesso a este sistema. Por favor utilize o aplicativo.",
          );
          return;
        }
        setUser(res.data.user);
        localStorage.setItem("@interbrasil:token", res.data.jwt);
        api.defaults.headers.Authorization = `Bearer ${res.data.jwt}`;
        setSigned(true);
        localStorage.setItem(
          "@interbrasil:user",
          JSON.stringify(res.data.user),
        );
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Credenciais inválidas. Por favor, tente novamente.");
        console.log(err.response);
        setLoading(false);
      });
  }
  function SignOut() {
    localStorage.removeItem("@interbrasil:token");
    localStorage.removeItem("@interbrasil:user");
    setSigned(false);
  }

  return (
    <AuthContext.Provider
      value={{ signed: signed, loading, isAdmin, SignIn, SignOut, user }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
