import React from "react";
import styles from "./Quantidade.module.css";

const Quantidade = ({
  page,
  total,
  disponivel,
  concluidos,
  finalizados,
  andamentos,
  tentandoContato,
  capturado,
}) => {
  return (
    <div className={styles.quantidade}>
      <div className={styles.quantidadeBox}>
        <div className={styles.quantidadeBoxTitle}>Total</div>
        <div className={styles.qtd}>{total}</div>
      </div>
      {page === "leads" && (
        <div className={`${styles.quantidadeBox} cellAzulClaro`}>
          <div className={styles.quantidadeBoxTitle}>Disponíveis</div>
          <div className={styles.qtd}>{disponivel}</div>
        </div>
      )}
      <div className={`${styles.quantidadeBox} cellVerde`}>
        <div className={styles.quantidadeBoxTitle}>Concluídos</div>
        <div className={styles.qtd}>{concluidos}</div>
      </div>
      <div className={`${styles.quantidadeBox} cellVermelho`}>
        <div className={styles.quantidadeBoxTitle}>Finalizados</div>
        <div className={styles.qtd}>{finalizados}</div>
      </div>
      <div className={`${styles.quantidadeBox} cellAzul`}>
        <div className={styles.quantidadeBoxTitle}>Em andamento</div>
        <div className={styles.qtd}>{andamentos}</div>
      </div>
      <div className={`${styles.quantidadeBox} cellAmarelo`}>
        <div className={styles.quantidadeBoxTitle}>Tentando contato</div>
        <div className={styles.qtd}>{tentandoContato}</div>
      </div>
      <div className={`${styles.quantidadeBox} cellLilas`}>
        <div className={styles.quantidadeBoxTitle}>Capturados</div>
        <div className={styles.qtd}>{capturado}</div>
      </div>
    </div>
  );
};

export default Quantidade;
