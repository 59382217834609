import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import { Link } from "react-router-dom";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import EditIcon from "@material-ui/icons/Edit";

import api from "../services/api";
import Loading from "../components/Loading";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  Menu: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  addIconDiv: {
    borderRadius: "50%",
    backgroundColor: "#0A88CF",
    width: 60,
    height: 60,
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    bottom: 30,
    right: 50,
    padding: 15,
  },
  addIcon: {
    color: "#fff",
    alignSelf: "center",
    fontSize: 30,
  },
  gridList: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  table: {
    maxWidth: 800,
  },
  leadTitle: {
    color: "#3f51b5",
    fontSize: 30,
  },
  cellHead: {
    fontSize: "16px",
    color: "rgb(63, 81, 181)",
    fontWeight: "bold",
    padding: "16px",
    height: "55px",
  },
}));

function ResponsiveDrawer(props) {
  const classes = useStyles();
  const [entidades, setEntidades] = useState([]);
  const [loading, setLoading] = useState(true);

  function getEntidades() {
    api.get("/entidades").then((res) => {
      setEntidades(res.data);
      setLoading(false);
    });
  }

  useEffect(() => {
    getEntidades();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className={classes.toolbar} />
      {entidades.length > 0 ? (
        <>
          <h1 className={classes.leadTitle}>Entidades/Empresas</h1>
          <TableContainer component={Paper} className={classes.table}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cellHead}>
                    Entidade/Empresa
                  </TableCell>
                  <TableCell className={classes.cellHead} align="center">
                    Estado
                  </TableCell>
                  <TableCell className={classes.cellHead} align="center">
                    Empresa/Entidade
                  </TableCell>
                  <TableCell className={classes.cellHead} align="center">
                    Editar
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {entidades.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.descricao}
                    </TableCell>
                    <TableCell align="center">{row.uf}</TableCell>
                    <TableCell align="center">
                      {row.corretora.nome ? row.corretora.nome : "Nenhuma"}
                    </TableCell>
                    <TableCell align="center">
                      <Link to={`/editarentidade/${row?.id}`}>
                        <EditIcon />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <h1 className={classes.leadTitle}>Sem Entidades</h1>
      )}
      <div
        style={{
          position: "fixed",
          zIndex: "999",
          bottom: 30,
          right: 50,
          flexDirection: "row",
          display: "flex",
        }}
      >
        <div
          style={{
            borderRadius: 50,
            backgroundColor: "#0A88CF",
            width: 200,
            height: 50,
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Link
            style={{
              textDecoration: "none",
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
            to="cadastrarentidade"
          >
            <div style={{ fontWeight: "bold", color: "#fff" }}>
              Adicionar Entidade
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
