import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import api from "../services/api";
import Loading from "../components/Loading";
import Lead from "../components/Lead";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  table: {
    minWidth: 650,
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  leadTitle: {
    color: "#3f51b5",
    fontSize: 30,
    marginTop: 50,
  },
  addIconDiv: {
    borderRadius: "50%",
    backgroundColor: "#3f51b5",
    width: 100,
    height: 100,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: 70,
    right: 100,
    padding: 15,
  },
  addIcon: {
    color: "#fff",
    alignSelf: "center",
    fontSize: 70,
  },
  corretor: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    borderWidth: 2,
    borderColor: "#3f51b5",
    borderStyle: "solid",
    borderRadius: 5,
    width: 300,
    height: 120,
  },
  corretorData: {
    flexDirection: "column",
    display: "flex",
    paddingLeft: 10,
    borderLeftWidth: 2,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderColor: "#3f51b5",
    backgroundColor: "#3f51b5",
    borderStyle: "solid",
    height: 120,
    justifyContent: "center",
    width: 350,
  },
  corretorImg: {
    width: 100,
    height: 100,
    borderRadius: 50,
    marginRight: 10,
  },
  corretorName: {
    fontSize: 20,
    color: "#fff",
    fontWeight: "bold",
  },
}));

function ResponsiveDrawer(props) {
  const classes = useStyles();
  const [retornoApi, setRetornoApi] = useState([]);
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  async function getCorretor() {
    await api.get(`/users/${id}`).then((res) => {
      setRetornoApi(res.data);
      setLoading(false);

      let sortableItems = res.data.leads;

      sortableItems.sort((a, b) => {
        if (a["id"] < b["id"]) {
          return 1;
        }
        if (a["id"] > b["id"]) {
          return -1;
        }
        return 0;
      });
      setLeads(sortableItems);
    });
  }

  useEffect(() => {
    getCorretor();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className={classes.toolbar} />
      <div className={classes.corretor}>
        <div className={classes.corretorData}>
          <span className={classes.corretorName}>{retornoApi.username}</span>
          <span className={classes.corretorName}>{retornoApi.email}</span>
        </div>
      </div>
      <Lead
        page="corretor"
        retornoApi={leads}
        apiContagem={leads}
        loading={loading}
        corretor={retornoApi.username}
        corretora={retornoApi.corretora.nome}
      />
    </>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
