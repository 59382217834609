import React, { useEffect, useState } from "react";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { IoMdArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";

import TelaDisponivel from "../../assets/images/TelasMobile/Disponivel.jpg";
import LeadCapturado from "../../assets/images/TelasMobile/LeadCapturado.jpg";
import LeadsCapturados from "../../assets/images/TelasMobile/LeadsCapturados.jpg";
import LeadFinalizados from "../../assets/images/TelasMobile/Finalizados.jpg";
import LeadFinalizadoDetalhe from "../../assets/images/TelasMobile/FinalizadosDetalhe.jpg";

import styles from "./styles.module.css";
import { useAuth } from "../context";

const stepsMobile = [
  {
    selector: "#step1",
    content:
      "Após efetuar o Login, o usuário será direcionado para a tela de Leads disponíveis, no qual todos os leads serão listados, sendo possível observar o nome do Lead, as maneiras de contato e a opção de capturar um Lead.",
  },
  {
    selector: "#step2",
    content:
      "Ao fazer a captura de um Lead, será possível observar todas as informações sobre ele, assim como a opção de alterar o status dele para Finalizar, Pendente e Concluído com  sucesso. Além disso, também é possível efetuar a devolução do Lead clicando em Devolver LEAD.",
  },
  {
    selector: "#step3",
    content:
      "A próxima tela é a de Leads capturados, no qual listará todos os Leads que foram capturados pelo usuário, sendo possível também exibir as informações, alterar o status e devolver o Lead ao clicar nele.",
  },
  {
    selector: "#step4",
    content:
      "A tela de Leads finalizados exibirá todos os Leads que já foram finalizados pelo usuário.",
  },
  {
    selector: "#step5",
    content:
      "Por fim,ao clicar em um lead finalizadoos detalhes sobre o Lead serão mostrados, não sendo mais possível alterar o status dele.",
  },
];

export function TutorialMobile() {
  const [isTourOpen, setIsTourOpen] = useState(false);
  const { signed } = useAuth();

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  useEffect(() => {
    setTimeout(() => {
      setIsTourOpen(true);
    }, 1000);
  }, []);

  return (
    <section className={styles.tutorialMobile}>
      {!signed && (
        <Link to="/login">
          <IoMdArrowBack size={18} />
        </Link>
      )}
      <Tour
        rounded={5}
        scrollOffset={0}
        steps={stepsMobile}
        startAt={0}
        isOpen={isTourOpen}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        onRequestClose={() => setIsTourOpen(false)}
      ></Tour>
      <div>
        <img id="step1" src={TelaDisponivel} alt="Tela de Leads Disponíveis" />
      </div>
      <div>
        <img id="step2" src={LeadCapturado} alt="Tela de Leads Capturado" />
      </div>
      <div>
        <img
          id="step3"
          src={LeadsCapturados}
          alt="Tela listagem de capturados "
        />
      </div>
      <div>
        <img id="step4" src={LeadFinalizados} alt="Tela de Leads Finalizados" />
      </div>
      <div>
        <img
          id="step5"
          src={LeadFinalizadoDetalhe}
          alt="Tela de detalhe Leads Finalizados"
        />
      </div>
    </section>
  );
}
