import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import api from "../services/api";
// import Loading from "../components/Loading";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootTextField: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 400,
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  table: {
    minWidth: 650,
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  leadTitle: {
    color: "#3f51b5",
    fontSize: 30,
    marginTop: 50,
    textAlign: "center",
  },
}));

function ResponsiveDrawer(props) {
  const classes = useStyles();
  const { id } = useParams();
  const [nomeCorretor, setNomeCorretor] = useState("");
  const [emailCorretor, setEmailCorretor] = useState("");
  const [senhaCorretor, setSenhaCorretor] = useState("");
  const [confirmarSenhaCorretor, setConfirmarSenhaCorretor] = useState("");
  // const [loading, setLoading] = useState(true);
  // const [entidades, setEntidades] = useState({});
  // const [errorEntidades, setErrorEntidades] = React.useState(false);
  const [enviando, setEnviando] = useState(false);

  // const [state, setState] = useState({});

  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  useEffect(() => {
    if (id) {
      (async () => {
        const { data } = await api.get(`/users/${id}`);
        setNomeCorretor(data.username);
        setEmailCorretor(data.email);
        // setLoading(false);
        // (async () => {
        //   await api
        //     .get(`/entidades`)
        //     .then((res) => {
        //       setEntidades(res.data);
        //       res.data.map((item) => {
        //         setState({ ...state, [item.id.toString()]: false });
        //       });
        //     })
        //     .catch((err) => {});
        // })();
      })();
    }
  }, [id]);

  async function salvarRelatorio(e) {
    e.preventDefault();
    if (confirmarSenhaCorretor !== senhaCorretor) {
      toast.error("As senhas não conferem!");
      return;
    }

    // let entidadesArray = [];
    // Object.entries(state).map((el) => {
    //   if (el[1] === true) {
    //     entidadesArray.push(el[0]);
    //   }
    //   return entidadesArray;
    // });

    // if (entidadesArray.length < 1) {
    //   setErrorEntidades(true);
    //   return;
    // } else {
    //   setErrorEntidades(false);
    // }

    setEnviando(true);

    var obj = {
      username: nomeCorretor,
      email: emailCorretor,
      password: senhaCorretor,
      tipo_usuario: 2,
    };

    try {
      await api
        .put(`/users/${id}`, obj, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          toast.success("Corretora foi editado!");
          setSenhaCorretor("");
          setConfirmarSenhaCorretor("");
        });
      setEnviando(false);
    } catch (err) {
      toast.error(
        "Falha em editar a corretora. Por favor, verifique sua conexão, e tente novamente em alguns minutos.",
      );
    }
  }

  return (
    <>
      <div className={classes.toolbar} />
      <h1 className={classes.leadTitle}>Editar Corretora</h1>
      <form
        className={classes.rootTextField}
        autoComplete="off"
        onSubmit={salvarRelatorio}
      >
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Grid>
            <TextField
              id="outlined-start-adornment"
              label="Nome da Corretora"
              variant="outlined"
              required
              value={nomeCorretor}
              onChange={(e) => {
                setNomeCorretor(e.target.value);
              }}
            />
          </Grid>
          <Grid>
            <TextField
              id="outlined-start-adornment"
              label="E-mail"
              disabled={true}
              type="email"
              variant="outlined"
              required
              value={emailCorretor}
              onChange={(e) => {
                setEmailCorretor(e.target.value);
              }}
            />
          </Grid>
          {/* <Grid style={{ maxWidth: "400px", margin: "10px 0" }}>
            <span
              style={{
                fontSize: "1rem",
                color: "gray",
                fontWeight: "400",
                lineHeight: "1.5",
                letterSpacing: "0.00938em",
              }}
            >
              Entidades/Empresas: *
            </span>
            <FormGroup row>
              {entidades.map((item) => (
                <FormControlLabel
                  key={item.id}
                  control={
                    <Checkbox
                      // checked={state[item.id.toString()]}
                      onChange={handleChange}
                      name={item.id.toString()}
                      color="primary"
                    />
                  }
                  label={item.descricao}
                />
              ))}
            </FormGroup>
            {errorEntidades && (
              <span style={{ marginTop: "2px", color: "red" }}>
                Por favor, selecione uma ou mais opções
              </span>
            )}
          </Grid> */}
          <Grid>
            <TextField
              id="outlined-start-adornment"
              label="Senha"
              type="password"
              variant="outlined"
              required
              value={senhaCorretor}
              onChange={(e) => {
                setSenhaCorretor(e.target.value);
              }}
            />
          </Grid>
          <Grid>
            <TextField
              id="outlined-start-adornment"
              label="Confirmat Senha"
              type="password"
              variant="outlined"
              required
              value={confirmarSenhaCorretor}
              onChange={(e) => {
                setConfirmarSenhaCorretor(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        {enviando ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled
            style={{ marginTop: 50 }}
          >
            Salvando...
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            style={{ marginTop: 50 }}
          >
            Salvar
          </Button>
        )}
      </form>
    </>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
