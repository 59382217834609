import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { useAuth } from "../components/context";
import api from "../services/api";
import Loading from "../components/Loading";
import Lead from "../components/Lead";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../styles/paginacao.module.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function ResponsiveDrawer(props) {
  const [retornoApi, setRetornoApi] = useState({ leads: [] });
  const [loading, setLoading] = useState(true);
  const [id2, setId2] = useState();
  const { user } = useAuth();

  const [offset, setOffset] = useState(0);
  const [info, setInfo] = useState({});
  const LIMIT = 40;

  const { id } = useParams();

  const leads = [];

  const classes = useStyles();
  const pages = Math.ceil(info.length / LIMIT);

  const [page, setPage] = React.useState(offset);

  const handleChange = (event, value) => {
    setPage(value);
    setOffset((value - 1) * LIMIT);
  };

  function isCorretora() {
    if (user.tipo_usuario.nome === "Corretora") {
      setId2(user.corretora.id);
    } else {
      setId2(id);
    }
  }

  async function getCorretora() {
    setLoading(true);
    await api.get(`/leads`).then((res) => {
      setInfo(res.data);
    });
    if (user.tipo_usuario.nome === "Corretora") {
      if (id2) {
        await api
          .get(`/leads`, {
            params: {
              _limit: LIMIT,
              _start: offset,
              _sort: "id:desc",
            },
          })
          .then((res) => {
            res.data.map((lead) => {
              if (lead.id_entidade === user.corretora.id_entidades) {
                leads.push(lead);
              }
            });
            setRetornoApi({ leads: leads });
            setLoading(false);
          });
      }
    } else if (user.tipo_usuario.nome === "Admin") {
      await api
        .get(`/leads`, {
          params: {
            _limit: LIMIT,
            _start: offset,
            _sort: "id:desc",
          },
        })
        .then((res) => {
          setRetornoApi({ leads: res.data });
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    getCorretora();
    isCorretora();
  }, [id2, offset]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <Lead
        page="leads"
        retornoApi={retornoApi.leads}
        apiContagem={info}
        loading={loading}
      />
      {info.length > LIMIT && (
        <div className={classes.root}>
          <Pagination
            className={styles.paginacao}
            color="primary"
            page={page}
            onChange={handleChange}
            count={pages}
            hidePrevButton
            hideNextButton
          />
        </div>
      )}
    </>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
