import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import Home from "../assets/images/TelasWeb/Home.png";
import CorretoraInfo from "../assets/images/TelasWeb/CorretoraInfo.png";
import CadastroCorretor from "../assets/images/TelasWeb/CadastroCorretor.png";
import Entidades from "../assets/images/TelasWeb/EntidadesEmpresas.png";
import CadastrarEntidade from "../assets/images/TelasWeb/CadastroEntidadeEmpresa.png";
import EditarEntidade from "../assets/images/TelasWeb/EditarEntidadeEmpresa.png";
import Usuarios from "../assets/images/TelasWeb/TelaUsuarios.png";
import Leads from "../assets/images/TelasWeb/TelaLeads.png";
import LeadInfo from "../assets/images/TelasWeb/LeadInfo.png";
import TelaLogin from "../assets/images/TelasWeb/TelaLogin.png";

import styles from "../styles/tutorial.module.css";

const stepsWeb = [
  {
    selector: "#step1",
    content:
      "Para ter acesso às funcionalidades da aplicação, é necessário efetuar o Login.",
  },
  {
    selector: "#step2",
    content:
      "Após efetuar o Login, o usuário será direcionado para a home, onde são listadas todas as corretoras disponíveis, sendo possível selecionar uma corretora especifica para verificar as informações e cadaastrar uma nova corretora.",
  },
  {
    selector: "#step3",
    content:
      "Clicando em 'Ver corretora', o usuário será redirecionado para as especificações da corretora selecionada, sendo possível ver os corretotres ligados a ela e os leads atribuidos a essa corretora. Além disso é possível adicionar um novo corretor e editar os dados de um já cadastrado.",
  },
  {
    selector: "#step4",
    content:
      "Clicando na botão 'Adicionar Corretora', o usuário será redirecionado para tela de cadastrar corretora para efetuar um novo cadastro.",
  },
  {
    selector: "#step5",
    content:
      "A proxima opção da barra lateral é a 'Entidades' que irá redirecionar o usuário para tela de entidades, na qual listará todas as empresas e entidades cadastradas na aplicação, sendo possível cadastrar uma nova ou editar uma já cadastrada.",
  },
  {
    selector: "#step6",
    content:
      "Escolhendo a opção de cadastro de Entidade, o usuário será redirecionado para a tela de cadastro de Entidade, na qual é possível realizar o cadastro de uma nova entidade, especificando o Nome da entidade, Estado e qual Entidade/Empresa ela pertence.",
  },
  {
    selector: "#step7",
    content:
      "Escolhendo a opção de editar os dados de uma Entidade, o usuário será redirecionado para a tela de edição de dados da Entidade, sendo possível atualizar os dados.",
  },
  {
    selector: "#step8",
    content:
      "A proxima opção da barra lateral é a 'Usuários' que irá redirecionar o usuário para tela de usuários, na qual lista todos os usuários da aplicação.",
  },
  {
    selector: "#step9",
    content:
      "A proxima opção da barra lateral é a 'Leads' que irá redirecionar o usuário para a listagem de Leads, que fornece um gráfico com as informações dos Leads e, além disso, uma tabela com o resumo dos dados.",
  },
  {
    selector: "#step10",
    content:
      "Por fim, clicar em um dos Leads da tabela, será exibido todas as informações sobre aquele Lead.",
  },
];

function ResponsiveDrawer() {
  const [isTourOpen, setIsTourOpen] = useState(false);

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  const getWidthScreen = useCallback(() => {
    return window.innerWidth > 800;
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (getWidthScreen) {
        setIsTourOpen(true);
      }
    }, 1000);
  }, []);

  return (
    <div className={styles.tutorial}>
      <section>
        {getWidthScreen() && (
          <Tour
            rounded={5}
            startAt={0}
            scrollOffset={0}
            steps={stepsWeb}
            isOpen={isTourOpen}
            onAfterOpen={disableBody}
            onBeforeClose={enableBody}
            onRequestClose={() => setIsTourOpen(false)}
          ></Tour>
        )}
        <div>
          <img id="step1" src={TelaLogin} alt="Tela de login" />
        </div>
        <div>
          <img id="step2" src={Home} alt="Tela de Leads" />
        </div>
        <div>
          <img id="step3" src={CorretoraInfo} alt="Tela de Informações da corretora" />
        </div>
        <div>
          <img
            id="step4"
            src={CadastroCorretor}
            alt="Tela de Cadastro de corretora"
          />
        </div>
        <div>
          <img
            id="step5"
            src={Entidades}
            alt="Tela de Entidade"
          />
        </div>
        <div>
          <img
            id="step6"
            src={CadastrarEntidade}
            alt="Tela de cadastro de Entidade"
          />
        </div>
        <div>
          <img
            id="step7"
            src={EditarEntidade}
            alt="Tela de"
          />
        </div>
        <div>
          <img id="step8" src={Usuarios} alt="Tela Usuários" />
        </div>
        <div>
          <img id="step9" src={Leads} alt="Tela de Leads" />
        </div>
        <div>
          <img id="step10" src={LeadInfo} alt="Tela de detalhe do Lead" />
        </div>
      </section>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
