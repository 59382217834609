import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "../pages/login";
import { ToastContainer } from "react-toastify";
import { TutorialMobile } from "../components/TutorialMobile";
import { useAuth } from "../components/context";

export default function Index() {
  const { signed } = useAuth();
  const [login, setLogin] = useState(false);

  useEffect(() => {
    if (!signed) {
      setLogin(true);
    }
  }, [signed]);

  return (
    <Router>
      <ToastContainer />
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/tutorial-mobile" component={TutorialMobile} />
        {login && <Redirect to="/login" />}
      </Switch>
    </Router>
  );
}
