import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Corretora from "../pages/corretora";
import Corretor from "../pages/corretor";
import CadastrarCorretor from "../pages/cadastrarCorretor";
import CadastrarCorretora from "../pages/cadastrarCorretora";
import CadastrarEntidade from "../pages/cadastrarEntidade";
import Entidades from "../pages/entidades";
import CadastrarAdmin from "../pages/cadastrarAdmin";
import EditarAdmin from "../pages/editarAdmin";
import EditarCorretora from "../pages/editarCorretora";
import EditarEntidade from "../pages/editarEntidade";
import Home from "../pages/home";
import Leads from "../pages/leads";
import Tutorial from "../pages/tutorial";
import Admin from "../pages/admin";
import { ToastContainer } from "react-toastify";
import { useAuth } from "../components/context";

import Container from "../components/Container";
import { TutorialMobile } from "../components/TutorialMobile";

export default function Index() {
  const { user } = useAuth();
  return (
    <Router>
      <ToastContainer />
      <Container>
        <Switch>
          {/* {user.tipo_usuario.nome === "Admin" ? (
            <Route path="/" component={Home} exact />
          ) : (
            <Route path="/" component={Corretora} exact />
          )} */}

          <Route path="/" component={Home} exact />

          <Route path="/corretoras/:id" component={Corretora} />
          <Route path="/corretor/:id" component={Corretor} />
          <Route
            path="/cadastrarcorretor/:id"
            component={CadastrarCorretor}
            exact
          />
          <Route path="/leads" component={Leads} />
          <Route path="/tutorial" component={Tutorial} />
          <Route path="/tutorial-mobile" component={TutorialMobile} />

          <Route path="/listaadmins" component={Admin} />
          <Route path="/listacorretoras" component={Home} />
          <Route path="/leads" component={Leads} />
          <Route path="/cadastrarcorretora" component={CadastrarCorretora} />
          <Route path="/cadastrarentidade" component={CadastrarEntidade} />
          <Route path="/entidades" component={Entidades} />
          <Route path="/cadastraradmin" component={CadastrarAdmin} />
          <Route path="/editaradmin/:id" component={EditarAdmin} />
          <Route
            path="/editarcorretora/:id"
            component={EditarCorretora}
            exact
          />
          <Route path="/editarentidade/:id" component={EditarEntidade} exact />
        </Switch>
        <Redirect to="/" />
      </Container>
    </Router>
  );
}
