import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// import Select from "@material-ui/core/Select";
// import InputLabel from "@material-ui/core/InputLabel";
// import MenuItem from "@material-ui/core/MenuItem";
// import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import api from "../services/api";
// import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
// import Loading from "../components/Loading";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootTextField: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 500,
      [theme.breakpoints.down("sm")]: {
        maxWidth: 300,
      },
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 50,
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  table: {
    minWidth: 650,
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  leadTitle: {
    color: "#3f51b5",
    fontSize: 30,
    marginTop: 50,
    textAlign: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    width: 500,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 300,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function ResponsiveDrawer(props) {
  const classes = useStyles();
  const [nomeCorretora, setNomeCorretora] = useState("");
  const [emailCorretora, setEmailCorretora] = useState("");
  const [senhaCorretora, setSenhaCorretora] = useState("");
  const [confirmSenhaCorretora, setConfirmSenhaCorretora] = useState("");
  const [enviando, setEnviando] = useState(false);
  // const [loading, setLoading] = useState(true);

  // const [errorEntidades, setErrorEntidades] = React.useState(false);
  // const [entidades, setEntidades] = useState([]);
  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  // const [state, setState] = useState({});

  // async function getEntidades() {
  //   await api
  //     .get(`/entidades`)
  //     .then((res) => {
  //       // setEntidades(res.data);
  //       setLoading(false);
  //       res.data.map((item) => {
  //         setState({ ...state, [item.id.toString()]: false });
  //       });
  //     })
  //     .catch((err) => {});
  // }

  // React.useEffect(() => {
  //   getEntidades();
  // }, []);

  function salvarRelatorio(e) {
    e.preventDefault();

    if (senhaCorretora !== confirmSenhaCorretora) {
      toast.error("As senhas não conferem!");
      return;
    }

    // let entidadesArray = [];
    // Object.entries(state).map((el) => {
    //   if (el[1] === true) {
    //     entidadesArray.push(el[0]);
    //   }
    //   return entidadesArray;
    // });

    // if (entidadesArray.length < 1) {
    //   setErrorEntidades(true);
    //   return;
    // } else {
    //   setErrorEntidades(false);
    // }

    setEnviando(true);

    api
      .post(`/corretoras`, {
        nome: nomeCorretora,
        id_entidades: 0,
        // entidades: entidadesArray,
      })
      .then(async (res) => {
        api
          .post(`/auth/local/register`, {
            username: nomeCorretora,
            email: emailCorretora,
            id_entidade: 0,
            password: senhaCorretora,
            confirmed: true,
            tipo_usuario: 2,
            foto: null,
            corretora: res.data.id,
          })
          .then((res) => {
            toast.success("Corretora salva com sucesso!");
            setNomeCorretora("");
            setSenhaCorretora("");
            setEmailCorretora("");
            setConfirmSenhaCorretora("");
            setEnviando(false);
          })
          .catch((err) => {
            console.log("ERR", err.response);
            toast.error(
              "Falha ao salvar o relatório. E-mail de usuário já cadastrado!",
            );
            setEnviando(false);
          });
      })
      .catch((err) => {
        console.log("SERVIDOR", err.response);
        toast.error(
          "Falha ao salvar o relatório. Por favor, verifique sua conexão, e tente novamente em alguns minutos.",
        );
        setEnviando(false);
        console.log("erro", err?.response?.data?.error);
        console.log("msg", err?.response?.data?.message);
      });
  }

  return (
    <>
      <div className={classes.toolbar} />
      <h1 className={classes.leadTitle}>Cadastrar Corretora</h1>
      <form
        className={classes.rootTextField}
        autoComplete="off"
        onSubmit={salvarRelatorio}
      >
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Grid>
            <TextField
              id="outlined-start-adornment1"
              label="Nome da Corretora"
              variant="outlined"
              required
              value={nomeCorretora}
              onChange={(e) => {
                setNomeCorretora(e.target.value);
              }}
            />
          </Grid>
          <Grid>
            <TextField
              id="outlined-start-adornment2"
              label="E-mail"
              type="email"
              variant="outlined"
              required
              value={emailCorretora}
              onChange={(e) => {
                setEmailCorretora(e.target.value);
              }}
            />
          </Grid>
          {/* <Grid style={{ maxWidth: "500px", margin: "10px 0" }}>
            <span
              style={{
                fontSize: "1rem",
                color: "gray",
                fontWeight: "400",
                lineHeight: "1.5",
                letterSpacing: "0.00938em",
              }}
            >
              Entidades/Empresas: *
            </span>
            <FormGroup row>
              {entidades.map((item) => (
                <FormControlLabel
                  key={item.id}
                  control={
                    <Checkbox
                      // checked={state[item.id.toString()]}
                      onChange={handleChange}
                      name={item.id.toString()}
                      color="primary"
                    />
                  }
                  label={item.descricao}
                />
              ))}
            </FormGroup>
            {errorEntidades && (
              <span style={{ marginTop: "2px", color: "red" }}>
                Por favor, selecione uma ou mais opções
              </span>
            )}
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={entidade}
                onChange={(e) => {
                  setEntidade(e.target.value);
                }}
                label="Entidade"
                required
              >
                {entidades.map((item) => (
                  <MenuItem value={item.id}>{item.descricao}</MenuItem>
                ))}
              </Select>
          </Grid> */}
          <Grid>
            <TextField
              id="outlined-start-adornment3"
              label="Senha"
              type="password"
              variant="outlined"
              required
              value={senhaCorretora}
              onChange={(e) => {
                setSenhaCorretora(e.target.value);
              }}
            />
          </Grid>
          <Grid>
            <TextField
              id="outlined-start-adornment4"
              label="Confirmar Senha"
              type="password"
              variant="outlined"
              required
              value={confirmSenhaCorretora}
              onChange={(e) => {
                setConfirmSenhaCorretora(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        {enviando ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled
            style={{ marginTop: 50 }}
          >
            Salvando...
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            style={{ marginTop: 50 }}
          >
            Salvar
          </Button>
        )}
      </form>
    </>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
