import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import HomeCard from "../components/card";
import GridList from "@material-ui/core/GridList";
import { Link } from "react-router-dom";

import api from "../services/api";
import baseUrl from "../services/baseUrl";
import Loading from "../components/Loading";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  Menu: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  addIconDiv: {
    borderRadius: "50%",
    backgroundColor: "#0A88CF",
    width: 60,
    height: 60,
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    bottom: 30,
    right: 50,
    padding: 15,
  },
  addIcon: {
    color: "#fff",
    alignSelf: "center",
    fontSize: 30,
  },
  gridList: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

function ResponsiveDrawer(props) {
  const classes = useStyles();
  const [corretoras, setCorretoras] = useState([]);
  const [loading, setLoading] = useState(true);

  function getCorretoras() {
    api.get("/users?tipo_usuario=2").then((res) => {
      setCorretoras(res.data);
      setLoading(false);
    });
  }

  useEffect(() => {
    getCorretoras();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className={classes.toolbar} />
      {corretoras.length > 0 ? (
        <GridList className={classes.gridList}>
          {corretoras.map((corretora) => (
            <Link
              key={corretora.corretora.id}
              to={`/corretoras/${corretora.corretora.id}`}
              style={{
                textDecoration: "none",
                margin: 10,
                justifyContent: "center",
                alignItems: "center",
                height: "max-content",
                maxWidth: "275px",
                width: "100%",
              }}
            >
              <HomeCard
                title={corretora.username}
                img={corretora.foto?.url && baseUrl + corretora.foto?.url}
                email={corretora?.email}
                label="Ver Corretora"
              />
            </Link>
          ))}
        </GridList>
      ) : (
        <h1 className={classes.leadTitle}>Sem Corretoras</h1>
      )}
      <div
        style={{
          position: "fixed",
          zIndex: "999",
          bottom: 30,
          right: 50,
          flexDirection: "row",
          display: "flex",
        }}
      >
        <div
          style={{
            borderRadius: 50,
            backgroundColor: "#0A88CF",
            width: 200,
            height: 50,
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Link
            style={{
              textDecoration: "none",
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
            to="cadastrarcorretora"
          >
            <div style={{ fontWeight: "bold", color: "#fff" }}>
              Adicionar Corretora
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
