import React from "react";
import Chart from "react-google-charts";
import styles from "./Charts.module.css";

const PieChart = ({ title, data, id }) => {
  return (
    <div className={styles.charts}>
      <Chart
        width={"auto"}
        height={"auto"}
        chartType="PieChart"
        loader={<div>Carregando Gráfico...</div>}
        data={data}
        options={{
          title: title,
          // sliceVisibilityThreshold: 0.2, // 20%
        }}
        rootProps={{ "data-testid": id }}
      />
    </div>
  );
};

export default PieChart;
