import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import api from "../services/api";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import Loading from "../components/Loading";

const estados = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootTextField: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 500,
      [theme.breakpoints.down("sm")]: {
        maxWidth: 300,
      },
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 50,
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  table: {
    minWidth: 650,
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  leadTitle: {
    color: "#3f51b5",
    fontSize: 30,
    marginTop: 50,
    textAlign: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    width: 500,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 300,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function ResponsiveDrawer(props) {
  const classes = useStyles();
  const [nomeEntidade, setNomeEntidade] = useState("");
  const [ufEntidade, setUfEntidade] = useState("");
  const [idCorretora, setIdCorretora] = useState();
  const [corretoras, setCorretoras] = useState();

  const [enviando, setEnviando] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getCorretora = async () => {
      await api.get(`/corretoras`).then(async (res) => {
        setCorretoras(res.data);
        setLoading(false);
      });
    };
    getCorretora();
  }, []);

  async function salvarRelatorio(e) {
    e.preventDefault();

    setEnviando(true);

    await api
      .post(`/entidades`, {
        descricao: nomeEntidade,
        uf: ufEntidade,
        corretora: idCorretora,
      })
      .then(async (res) => {
        console.log(res.data);
        await api
          .post(`/entidade-corretoras`, {
            id_corretora: idCorretora,
            id_entidade: res.data.id,
          })
          .then((res) => {
            toast.success("Entidade salva com sucesso!");
            setNomeEntidade("");
            setUfEntidade("");
            setEnviando(false);
          })
          .catch((err) => {
            console.log("ERR", err.response);
            toast.error("Falha ao salvar");
            setEnviando(false);
          });
      })
      .catch((err) => {
        console.log("SERVIDOR", err.response);
        toast.error(err?.response?.data?.message);
        setEnviando(false);
        console.log("erro", err?.response?.data?.error);
        console.log("msg", err?.response?.data?.message);
      });
  }

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className={classes.toolbar} />
      <h1 className={classes.leadTitle}>Cadastrar Entidade/Empresa</h1>
      <form
        className={classes.rootTextField}
        autoComplete="off"
        onSubmit={salvarRelatorio}
      >
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Grid>
            <TextField
              id="outlined-start-adornment1"
              label="Nome da Entidade/Empresa"
              variant="outlined"
              required
              value={nomeEntidade}
              onChange={(e) => {
                setNomeEntidade(e.target.value);
              }}
            />
          </Grid>
          <Grid>
            <FormControl className={classes.formControl}>
              <InputLabel id="estadoLabel">Estado: *</InputLabel>
              <Select
                labelId="estadoLabel"
                id="estado"
                value={ufEntidade}
                onChange={(e) => {
                  setUfEntidade(e.target.value);
                }}
                label="estadoLabel"
                required
              >
                {estados.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            <FormControl className={classes.formControl}>
              <InputLabel id="corretorasLabel">
                Entidades/Empresas: *
              </InputLabel>
              <Select
                labelId="corretorasLabel"
                id="corretoras"
                value={idCorretora}
                onChange={(e) => {
                  setIdCorretora(e.target.value);
                }}
                label="corretorasLabel"
                required
              >
                {corretoras.map((item) => (
                  <MenuItem value={item.id}>{item.nome}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {enviando ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled
            style={{ marginTop: 50 }}
          >
            Salvando...
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            style={{ marginTop: 50 }}
          >
            Salvar
          </Button>
        )}
      </form>
    </>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
