import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import React from "react";
// import HomeIcon from "@material-ui/icons/Home";
import BusinessCenterSharpIcon from "@material-ui/icons/BusinessCenterSharp";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import DescriptionIcon from "@material-ui/icons/Description";
import BusinessSharpIcon from "@material-ui/icons/BusinessSharp";
import { Link } from "react-router-dom";
import { useAuth } from "../context";
import logo from "../../assets/images/logo.png";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
}));
const Drawer = () => {
  const { SignOut, user } = useAuth();

  const classes = useStyles();

  return (
    <div>
      <img
        alt=""
        style={{ width: 239, position: "absolute", top: 5 }}
        src={logo}
      />
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <ListItem button key={"asd"}>
          <Link
            to={`/`}
            style={{
              textDecoration: "none",
              width: "100%",
              flexDirection: "row",
              display: "flex",
              color: "grey",
            }}
          >
            <ListItemIcon>
              <BusinessCenterSharpIcon />
            </ListItemIcon>
            <ListItemText primary={"Corretoras"} />
          </Link>
        </ListItem>
        <ListItem button key={"asd"}>
          <Link
            to={`/entidades`}
            style={{
              textDecoration: "none",
              width: "100%",
              flexDirection: "row",
              display: "flex",
              color: "grey",
            }}
          >
            <ListItemIcon>
              <BusinessSharpIcon />
            </ListItemIcon>
            <ListItemText primary={"Entidades"} />
          </Link>
        </ListItem>
        {user.tipo_usuario.nome === "Admin" && (
          <>
            <ListItem button key={"1"}>
              <Link
                to={`/listaadmins`}
                style={{
                  textDecoration: "none",
                  width: "100%",
                  flexDirection: "row",
                  display: "flex",
                  color: "grey",
                }}
              >
                <ListItemIcon>
                  <VerifiedUserIcon />
                </ListItemIcon>
                <ListItemText primary={"Usuários"} />
              </Link>
            </ListItem>
            <ListItem button key={"2"}>
              <Link
                to={`/leads`}
                style={{
                  textDecoration: "none",
                  width: "100%",
                  flexDirection: "row",
                  display: "flex",
                  color: "grey",
                }}
              >
                <ListItemIcon>
                  <AttachMoneyIcon />
                </ListItemIcon>
                <ListItemText primary={"Leads"} />
              </Link>
            </ListItem>
          </>
        )}
        <ListItem button key={"tutorial"}>
          <Link
            to={`/tutorial`}
            style={{
              textDecoration: "none",
              width: "100%",
              flexDirection: "row",
              display: "flex",
              color: "grey",
            }}
          >
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary={"Tutorial Web"} />
          </Link>
        </ListItem>
        <ListItem button key={"tutorialMobile"}>
          <Link
            to={`/tutorial-mobile`}
            style={{
              textDecoration: "none",
              width: "100%",
              flexDirection: "row",
              display: "flex",
              color: "grey",
            }}
          >
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary={"Tutorial Mobile"} />
          </Link>
        </ListItem>
        <ListItem button key={"qwe"}>
          <div
            onClick={SignOut}
            style={{
              textDecoration: "none",
              width: "100%",
              flexDirection: "row",
              display: "flex",
              color: "grey",
            }}
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={"Sair"} />
          </div>
        </ListItem>
      </List>
    </div>
  );
};

export default Drawer;
