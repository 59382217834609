import React from "react";
import Routes from "./routes/index";

import AuthProvider from "../src/components/context";

import "react-toastify/dist/ReactToastify.min.css";
import "./styles/loading.css";

function App() {
  return (
    <AuthProvider>
      <Routes />
    </AuthProvider>
  );
}

export default App;
