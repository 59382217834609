import React from "react";
import Auth from "./auth";
import Main from "./main";
import { useAuth } from "../components/context";

export default function Index() {
  const { signed } = useAuth();

  if (signed) {
    return <Main />;
  } else {
    return <Auth />;
  }
}
