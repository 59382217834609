import React from "react";
import "../styles/components/card.css";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Divider from "@material-ui/core/Divider";

function HomeCard(props) {
  return (
    <div
      className="Content-Wrapper"
      style={{ backgroundColor: `${props.color}` }}
    >
      <div className="TopRow">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            padding: "0px 10px",
            textAlign: "center",
          }}
        >
          <h1 className="StrgCard">{props.title}</h1>
          <h1 className="StrgEmail">{props.email}</h1>
        </div>
      </div>

      <Divider light />

      {props.label && (
        <div className="BottomRow">
          <span className="CorretoraName">
            {props.label ? props.label : "Ver Corretora"}
          </span>
          <ArrowForwardIosIcon className="IconFoward" />
        </div>
      )}
    </div>
  );
}

export default HomeCard;
