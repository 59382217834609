import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { GridList } from "@material-ui/core";
import { Link } from "react-router-dom";
import HomeCard from "../components/card";
import api from "../services/api";
import AddIcon from "@material-ui/icons/Add";
import baseUrl from "../services/baseUrl";
import { useAuth } from "../components/context";
import Loading from "../components/Loading";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  Menu: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  addIconDiv: {
    borderRadius: "50%",
    backgroundColor: "#0A88CF",
    width: 60,
    height: 60,
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    bottom: 30,
    right: 50,
    padding: 15,
  },
  addIcon: {
    color: "#fff",
    alignSelf: "center",
    fontSize: 30,
  },
}));

function ResponsiveDrawer(props) {
  const { user } = useAuth();
  const classes = useStyles();
  const [youself, setYouself] = useState([]);
  const [rest, setRest] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function myApi() {
      await api
        .get(`/users?tipo_usuario=1`)
        .then((res) => {
          let youself = res.data.filter((u) => u.id === user.id);
          let rest = res.data.filter((u) => u.id !== user.id);
          setYouself(youself);
          setRest(rest);
          setLoading(false);
        })
        .catch((err) => {
          console.log("ERROR", err);
        });
    }
    myApi();
  }, [user]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className={classes.toolbar} />
      <h1 className={classes.leadTitle}>Usuários</h1>
      <GridList cellHeight={60} className={classes.gridList} cols={4}>
        {youself?.map((user) => (
          <Link
            key={user.id}
            to={`/editaradmin/${user.id}`}
            style={{
              height: 150,
              textDecoration: "none",
              width: 250,
              margin: 20,
            }}
          >
            <HomeCard
              title={user.username}
              img={user.foto?.url && baseUrl + user.foto?.url}
              label="Editar"
            />
          </Link>
        ))}
        {rest?.map((user) => (
          <Link
            key={user.id}
            to={""}
            style={{
              height: 150,
              textDecoration: "none",
              width: 250,
              margin: 20,
            }}
          >
            <HomeCard
              title={user.username}
              img={user.foto?.url && baseUrl + user.foto?.url}
            />
          </Link>
        ))}
      </GridList>
      <Link className={classes.addIconDiv} to="cadastraradmin">
        <AddIcon className={classes.addIcon} />
      </Link>
    </>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
