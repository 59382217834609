import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import HomeCard from "../components/card";
import GridList from "@material-ui/core/GridList";
import { Link, useParams } from "react-router-dom";
import api from "../services/api";
import baseUrl from "../services/baseUrl";
import { useAuth } from "../components/context";
import Loading from "../components/Loading";
import Lead from "../components/Lead";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  table: {
    minWidth: 650,
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  leadTitle: {
    color: "#3f51b5",
    fontSize: 30,
    marginTop: 50,
  },
  addIconDiv: {
    cursor: "pointer",
    borderRadius: "50%",
    backgroundColor: "#0A88CF",
    width: 60,
    height: 60,
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    bottom: 30,
    right: 50,
    padding: 15,
  },
  addIcon: {
    color: "#fff",
    alignSelf: "center",
    fontSize: 30,
  },
  gridList: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

function ResponsiveDrawer(props) {
  const classes = useStyles();
  const [retornoApi, setRetornoApi] = useState([]);
  const [corretora, setCorretora] = useState();
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const { id } = useParams();

  const leadsArray = [];

  useEffect(() => {
    async function myAPi() {
      if (user?.tipo_usuario?.id === 1) {
        await api.get(`users?corretora=${id}&tipo_usuario=2`).then((res) => {
          setCorretora(res.data.length ? res.data[0] : {});
        });
      } else {
        await api.get(`/users/${user.id}`).then((res) => {
          setCorretora(res.data);
        });
      }
    }
    myAPi();
  }, [id, user]);

  useEffect(() => {
    if (corretora) {
      async function apis() {
        await api
          .get(`/leads`, {
            params: {
              _sort: "id:desc",
            },
          })
          .then((res) => {
            res.data.map((lead) => {
              if (
                lead.user?.corretora == corretora.corretora.id &&
                lead.status !== "disponivel"
              ) {
                leadsArray.push(lead);
              }
            });
            setLeads(leadsArray);
            setLoading(false);
          });
        await api
          .get(
            `/users?corretora=${id || corretora.corretora.id}&tipo_usuario=3`,
            {
              params: {
                _sort: "created_at",
              },
            },
          )
          .then((res) => {
            setRetornoApi(res.data);
            setLoading(false);
          });
      }
      apis();
    }
  }, [corretora]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className={classes.toolbar} />
      <GridList className={classes.gridList}>
        <Perfil corretora={corretora} user={user} />
        {retornoApi?.map((user) => (
          <Link
            key={user.id}
            to={`/corretor/${user.id}`}
            style={{
              textDecoration: "none",
              margin: 10,
              justifyContent: "center",
              alignItems: "center",
              height: "max-content",
              maxWidth: "275px",
              width: "100%",
            }}
          >
            <HomeCard
              title={user.username}
              email={user.email}
              label="Ver Corretor"
            />
          </Link>
        ))}
      </GridList>
      <div
        style={{
          width: "100%",
          height: 50,
          alignItems: "center",
          display: "flex",
          justifyContent: "start",
          margin: "20px 0 60px 0",
        }}
        className="btnCenter"
      >
        <Link
          style={{
            borderRadius: 50,
            backgroundColor: "#0A88CF",
            textDecoration: "none",
            display: "flex",
            width: 200,
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
          to={`/cadastrarcorretor/${id || corretora.corretora.id}`}
        >
          <div style={{ fontWeight: "bold", color: "#fff" }}>
            Adicionar Corretor
          </div>
        </Link>
      </div>
      <Lead
        page="corretora"
        apiContagem={leads}
        retornoApi={leads}
        loading={loading}
      />
    </>
  );
}

function Perfil({ corretora, user }) {
  if (!corretora) {
    return null;
  }
  // if (user.tipo_usuario.id === 1) {
  //   return (
  //     <div
  //       style={{
  //         textDecoration: "none",
  //         margin: 10,
  //         justifyContent: "center",
  //         alignItems: "center",
  //         height: "max-content",
  //         maxWidth: "275px",
  //         width: "100%",
  //       }}
  //     >
  //       <HomeCard
  //         title={corretora?.username}
  //         img={corretora?.foto?.url && baseUrl + corretora?.foto?.url}
  //         email={corretora?.email}
  //         label={""}
  //         color="#26326d"
  //       />
  //     </div>
  //   );
  // }
  return (
    <Link
      to={`/editarcorretora/${corretora?.id}`}
      style={{
        textDecoration: "none",
        margin: 10,
        justifyContent: "center",
        alignItems: "center",
        height: "max-content",
        maxWidth: "275px",
        width: "100%",
      }}
    >
      <HomeCard
        title={corretora?.username}
        img={corretora?.foto?.url && baseUrl + corretora?.foto?.url}
        email={corretora?.email}
        label={"Editar"}
      />
    </Link>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
