import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../src/assets/images/logo.png";
import { useAuth } from "../components/context";
import Skeleton from "@material-ui/lab/Skeleton";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit">INTERBRASIL</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "90%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function LoginComponent() {
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [skeleton, setSkeleton] = useState(true);
  const { SignIn, loading } = useAuth();
  const classes = useStyles();

  async function submit(e) {
    e.preventDefault();
    await SignIn({
      identifier: email,
      password: senha,
    });
  }

  function handleLoad() {
    setSkeleton(false);
  }

  return (
    <div className={classes.container}>
      <div className={classes.paper}>
        {skeleton && <Skeleton variant="rect" width={600} height={131} />}
        <img
          alt=""
          onLoad={handleLoad}
          style={{ maxWidth: 600, width: "100%" }}
          src={logo}
        />

        <form className={classes.form} onSubmit={submit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            value={senha}
            onChange={(e) => {
              setSenha(e.target.value);
            }}
          />
          {loading ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled
            >
              Carregando...
            </Button>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Login
            </Button>
          )}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </div>
  );
}
