import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import api from "../services/api";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootTextField: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 500,
      [theme.breakpoints.down("sm")]: {
        maxWidth: 300,
      },
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  table: {
    minWidth: 650,
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  leadTitle: {
    color: "#3f51b5",
    fontSize: 30,
    marginTop: 50,
    textAlign: "center",
  },
}));

function ResponsiveDrawer(props) {
  const classes = useStyles();
  const [nomeCorretor, setNomeCorretor] = useState("");
  const [emailCorretor, setEmailCorretor] = useState("");
  const [senhaCorretor, setSenhaCorretor] = useState("");
  const [confirmSenhaCorretor, setConfirmSenhaCorretor] = useState("");
  const [enviando, setEnviando] = useState(false);
  const [idEntidade, setIdEntidade] = useState("");
  const { id } = useParams();

  async function getIdEntidade() {
    await api.get(`/corretoras/${id}`).then((res) => {
      setIdEntidade(res.data.id_entidades);
    });
  }

  React.useEffect(() => {
    getIdEntidade();
  }, [idEntidade]);

  function salvarRelatorio(e) {
    e.preventDefault();
    setEnviando(true);

    if (confirmSenhaCorretor !== senhaCorretor) {
      toast.error("As senhas não conferem!");
      return;
    }

    const obj = {
      username: nomeCorretor,
      email: emailCorretor,
      password: senhaCorretor,
      corretora: id,
      entidade: idEntidade,
      tipo_usuario: 3,
      foto: null,
    };

    api
      .post(`/users`, obj)
      .then((res) => {
        toast.success("Corretor salvo com sucesso!");
        setNomeCorretor("");
        setEmailCorretor("");
        setSenhaCorretor("");
        setIdEntidade("");
        setConfirmSenhaCorretor("");
        setEnviando(false);
      })
      .catch((err) => {
        toast.error(
          "Falha ao salvar o corretor. Por favor, verifique sua conexão, e tente novamente em alguns minutos.",
        );
        console.log("erro", err?.response?.data?.error);
        console.log("msg", err?.response?.data?.message);
        setEnviando(false);
      });
  }

  return (
    <>
      <div className={classes.toolbar} />
      <h1 className={classes.leadTitle}>Cadastrar Corretor</h1>
      <form
        className={classes.rootTextField}
        autoComplete="off"
        onSubmit={salvarRelatorio}
      >
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Grid>
            <TextField
              id="outlined-start-adornment"
              label="Nome do Corretor"
              variant="outlined"
              required
              value={nomeCorretor}
              onChange={(e) => {
                setNomeCorretor(e.target.value);
              }}
            />
          </Grid>
          <Grid>
            <TextField
              id="outlined-start-adornment"
              label="E-mail"
              type="email"
              variant="outlined"
              required
              value={emailCorretor}
              onChange={(e) => {
                setEmailCorretor(e.target.value);
              }}
            />
          </Grid>
          <Grid>
            <TextField
              id="outlined-start-adornment"
              label="Senha"
              type="password"
              variant="outlined"
              required
              value={senhaCorretor}
              onChange={(e) => {
                setSenhaCorretor(e.target.value);
              }}
            />
          </Grid>
          <Grid>
            <TextField
              id="outlined-start-adornment"
              label="Confirmar Senha"
              type="password"
              variant="outlined"
              required
              value={confirmSenhaCorretor}
              onChange={(e) => {
                setConfirmSenhaCorretor(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        {enviando ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled
            style={{ marginTop: 50 }}
          >
            Salvando...
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            style={{ marginTop: 50 }}
          >
            Salvar
          </Button>
        )}
      </form>
    </>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
