import axios from "axios";
import base_url from "./baseUrl";
const token = localStorage.getItem("@interbrasil:token");

const api = axios.create({
  baseURL: base_url,
});
if (token) {
  api.defaults.headers.Authorization = `Bearer ${token}`;
}
export default api;
