import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import styles from "../styles/leads.module.css";
import { makeStyles } from "@material-ui/core/styles";
import PieChart from "./Chart/PieChart";
import api from "../services/api";
import Loading from "./Loading";
import Quantidade from "./Quantidade";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  leadTitle: {
    color: "#3f51b5",
    fontSize: 30,
  },
}));

const Lead = ({
  retornoApi,
  apiContagem,
  loading,
  page,
  corretor,
  corretora,
}) => {
  const classes = useStyles();
  const [concluidos, setConcluidos] = React.useState();
  const [finalizados, setFinalizados] = React.useState();
  const [andamentos, setAndamentos] = React.useState();
  const [tentandoContato, setTentandoContato] = React.useState();
  const [capturado, setCapturado] = React.useState();
  const [disponivel, setDisponivel] = React.useState();
  // const [entidades, setEntidades] = React.useState("");
  const [corretoras, setCorretoras] = React.useState("");
  const [topCorretor, setTopCorretor] = React.useState();
  const [topCorretora, setTopCorretora] = React.useState();
  const [busca, setBusca] = React.useState("");
  const [buscarPor, setBuscarpor] = React.useState("");
  const [inputBusca, setInputBusca] = React.useState(false);

  // FILTRO - INÍCIO ***********************************************************
  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  const { items, requestSort, sortConfig } = useSortableData(retornoApi);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  // FILTRO - FINAL ************************************************************

  const leadsFiltrados = React.useMemo(() => {
    const semAcento = busca.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const lowerBusca = semAcento.toLowerCase();

    if (buscarPor == 1) {
      setInputBusca(true);
      return items.filter((item) =>
        item.nome
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(lowerBusca),
      );
    } else if (buscarPor == 2) {
      setInputBusca(true);
      let arrayCorretores = [];
      for (let i = 0; i < items.length; i++) {
        if (items[i].user) {
          arrayCorretores.push(items[i]);
        }
      }
      if (busca.length > 0) {
        return arrayCorretores.filter((item) =>
          item.user.username
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(lowerBusca),
        );
      } else {
        return items;
      }
    } else if (buscarPor == 3) {
      setInputBusca(true);
      let arrayContratos = [];
      for (let i = 0; i < items.length; i++) {
        if (items[i].contrato) {
          arrayContratos.push(items[i]);
        }
      }
      if (busca.length > 0) {
        return arrayContratos.filter((item) =>
          item.contrato.toLowerCase().includes(lowerBusca),
        );
      } else {
        return items;
      }
    } else if (buscarPor == 4) {
      setInputBusca(true);

      const newArray = items.map((item) => {
        if (item.status == "em_andamento") {
          item.status = "Em andamento";
        } else if (item.status == "pendente") {
          item.status = "Tentando Contato";
        } else if (item.status == "liberado") {
          item.status = "Capturado";
        }
        return item;
      });

      return newArray.filter((item) =>
        item.status
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(lowerBusca),
      );
    } else {
      setInputBusca(false);
      return items;
    }
  }, [busca, items, buscarPor]);

  const handleModal = (props) => {
    const id = `modal-${props.currentTarget.id}`;
    document.getElementById(id).style.display = "flex";
  };

  function handleCloseModal(props) {
    document.getElementById(
      `modal-lead-${props.currentTarget.id}`,
    ).style.display = "none";
  }

  // async function getEntidades() {
  //   await api.get(`/entidades/`).then((res) => {
  //     setEntidades(res.data);
  //   });
  // }

  async function getCorretoras() {
    await api.get(`/corretoras`).then((res) => {
      setCorretoras(res.data);
    });
  }

  React.useEffect(() => {
    let qtdConcluidos = 0;
    let qtdFinalizados = 0;
    let qtdAndamentos = 0;
    let qtdTentantoContato = 0;
    let qtdCapturados = 0;
    let qtdDiponivel = 0;

    apiContagem?.map((item) => {
      if (item.status === "concluido") {
        qtdConcluidos++;
      } else if (item.status === "finalizado") {
        qtdFinalizados++;
      } else if (
        item.status === "em_andamento" ||
        item.status === "Em andamento"
      ) {
        qtdAndamentos++;
      } else if (
        item.status === "pendente" ||
        item.status === "Tentando Contato"
      ) {
        qtdTentantoContato++;
      } else if (item.status === "liberado" || item.status === "Capturado") {
        qtdCapturados++;
      } else if (item.status === "disponivel") {
        qtdDiponivel++;
      }
    });
    setConcluidos(qtdConcluidos);
    setFinalizados(qtdFinalizados);
    setAndamentos(qtdAndamentos);
    setTentandoContato(qtdTentantoContato);
    setCapturado(qtdCapturados);
    setDisponivel(qtdDiponivel);
    // getEntidades();
    getCorretoras();
  }, [retornoApi]);

  React.useEffect(() => {
    let arrayLeads = [];
    for (let i = 0; i < retornoApi.length; i++) {
      if (retornoApi[i].user) {
        arrayLeads.push(retornoApi[i].user);
      }
    }

    if (page == "corretora") {
      let tmp = [];
      for (var i = 0; i < arrayLeads.length; i++) {
        if (tmp.indexOf(arrayLeads[i].username) == -1) {
          tmp.push(arrayLeads[i].username);
        }
      }

      let map = arrayLeads.reduce(function (prev, cur) {
        prev[cur.username] = (prev[cur.username] || 0) + 1;
        return prev;
      }, []);

      let result = [["Top Corretores", "Corretores"]];

      for (let i = 0; i < tmp.length; i++) {
        result.push([tmp[i], map[tmp[i]]]);
      }

      setTopCorretor(result);
    }

    if (page == "leads") {
      let tmp2 = [];
      for (var i = 0; i < arrayLeads.length; i++) {
        if (tmp2.indexOf(arrayLeads[i].corretora) == -1) {
          tmp2.push(arrayLeads[i].corretora);
        }
      }

      let map2 = arrayLeads.reduce(function (prev, cur) {
        prev[cur.corretora] = (prev[cur.corretora] || 0) + 1;
        return prev;
      }, []);

      let result2 = [["Top Corretoras", "Corretoras"]];

      for (let i = 0; i < tmp2.length; i++) {
        let corretora =
          corretoras && corretoras.find((corretora) => corretora.id == tmp2[i]);
        result2.push([corretora.nome, map2[tmp2[i]]]);
      }

      setTopCorretora(result2);
    }
  }, [corretoras, retornoApi, page]);

  const dataChart = [
    ["Lead", "Status"],
    ["Em andamento", andamentos],
    ["Finalizados", finalizados],
    ["Tentando contato", tentandoContato],
    ["Concluídos", concluidos],
    ["Capturados", capturado],
    ["Disponíveis", disponivel],
  ];

  return retornoApi.length < 1 ? (
    <>
      <div className={classes.toolbar} />
      <h1 className={classes.leadTitle}>Sem Leads</h1>
    </>
  ) : (
    <>
      <div className={styles.leadHead}>
        {page == "leads" && <div className={classes.toolbar} />}
        <h1 className={classes.leadTitle}>Leads</h1>
        {loading ? (
          <Loading />
        ) : (
          dataChart &&
          dataChart.length > 1 && (
            <div className={styles.charts}>
              <PieChart title="Status" data={dataChart} />
              {page === "corretora" && (
                <PieChart title="Top Corretores" data={topCorretor} />
              )}
              {page === "leads" && (
                <PieChart title="Top Corretoras" data={topCorretora} />
              )}
            </div>
          )
        )}
      </div>
      <section className={styles.leadsBox}>
        <Quantidade
          page={page}
          total={apiContagem.length}
          disponivel={disponivel}
          concluidos={concluidos}
          finalizados={finalizados}
          andamentos={andamentos}
          tentandoContato={tentandoContato}
          capturado={capturado}
        />
        <div className={styles.busca}>
          <Grid>
            <FormControl variant="outlined" className={styles.selectBusca}>
              <InputLabel id="select-busca-label">Buscar por:</InputLabel>
              <Select
                labelId="select-busca-label"
                id="select-busca"
                value={buscarPor}
                onChange={(e) => {
                  setBuscarpor(e.target.value);
                }}
                label="Buscar Por"
              >
                <MenuItem value={0}>Nenhum</MenuItem>
                <MenuItem value={1}>Nome</MenuItem>
                <MenuItem value={2}>Corretor</MenuItem>
                <MenuItem value={3}>Contrato</MenuItem>
                <MenuItem value={4}>Status</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {inputBusca && (
            <Grid>
              <TextField
                label="Digite sua busca..."
                type="text"
                variant="outlined"
                value={busca}
                onChange={(ev) => setBusca(ev.target.value)}
                className={styles.inputBusca}
              />
            </Grid>
          )}
        </div>
        <section className={styles.leads}>
          <div className={styles.leadsHead}>
            <div
              onClick={() => requestSort("id")}
              className={getClassNamesFor("id")}
            >
              Id
              <ArrowDownwardIcon className="ascending" />
              <ArrowUpwardIcon className="descending" />
            </div>
            <div
              onClick={() => requestSort("nome")}
              className={getClassNamesFor("nome")}
            >
              Nome
              <ArrowDownwardIcon className="ascending" />
              <ArrowUpwardIcon className="descending" />
            </div>
            {page !== "corretor" && (
              <div className={styles.leadsHeadCell}>Corretor</div>
            )}
            <div
              onClick={() => requestSort("created_at")}
              className={getClassNamesFor("created_at")}
            >
              Criado
              <ArrowDownwardIcon className="ascending" />
              <ArrowUpwardIcon className="descending" />
            </div>
            <div
              onClick={() => requestSort("updated_at")}
              className={getClassNamesFor("updated_at")}
            >
              Atualizado
              <ArrowDownwardIcon className="ascending" />
              <ArrowUpwardIcon className="descending" />
            </div>
            <div
              onClick={() => requestSort("status")}
              className={getClassNamesFor("status")}
            >
              Status
              <ArrowDownwardIcon className="ascending" />
              <ArrowUpwardIcon className="descending" />
            </div>
            <div
              onClick={() => requestSort("contrato")}
              className={getClassNamesFor("contrato")}
            >
              Contrato
              <ArrowDownwardIcon className="ascending" />
              <ArrowUpwardIcon className="descending" />
            </div>
          </div>
          {!loading &&
            leadsFiltrados?.map((row) => (
              <div key={`modal-lead-${row.id}`}>
                <div
                  key={row.id}
                  id={`lead-${row.id}`}
                  onClick={handleModal}
                  className={styles.leadsRow}
                >
                  <div className={styles.leadsRowCell}>{row?.id}</div>
                  <div className={styles.leadsRowCell}>{row?.nome}</div>
                  {page !== "corretor" && (
                    <div className={styles.leadsRowCell}>
                      {row?.user?.username}
                    </div>
                  )}
                  <div className={styles.leadsRowCell}>
                    {row?.created_at.split("T")[0].split("-")[2]}/
                    {row?.created_at.split("T")[0].split("-")[1]}/
                    {row?.created_at.split("T")[0].split("-")[0]}
                  </div>
                  <div className={styles.leadsRowCell}>
                    {row?.dt_mov.split("T")[0].split("-")[2]}/
                    {row?.dt_mov.split("T")[0].split("-")[1]}/
                    {row?.dt_mov.split("T")[0].split("-")[0]}
                  </div>
                  <div
                    style={{ fontWeight: "bold" }}
                    className={`${styles.leadsRowCell} ${
                      row?.status === "concluido"
                        ? "cellVerde"
                        : row?.status === "finalizado"
                        ? "cellVermelho"
                        : row?.status === "em_andamento" ||
                          row?.status === "Em andamento"
                        ? "cellAzul"
                        : row?.status === "pendente" ||
                          row?.status === "Tentando Contato"
                        ? "cellAmarelo"
                        : row?.status === "liberado" ||
                          row?.status === "Capturado"
                        ? "cellLilas"
                        : row?.status === "disponivel"
                        ? "cellAzulClaro"
                        : ""
                    }`}
                  >
                    {row?.status === "em_andamento"
                      ? "Em andamento"
                      : row?.status === "concluido"
                      ? "Concluído"
                      : row?.status === "pendente"
                      ? "Tentando Contato"
                      : row?.status === "finalizado"
                      ? "Finalizado"
                      : row?.status === "disponivel"
                      ? "Disponível"
                      : row?.status === "liberado"
                      ? "Capturado"
                      : row?.status}
                  </div>
                  <div
                    style={{ fontWeight: "bold" }}
                    className={styles.leadsRowCell}
                  >
                    {row?.status == "concluido" ? row?.contrato : "-"}
                  </div>
                </div>

                {/* MODAL - INICIO */}
                <div
                  key={row.email}
                  id={`modal-lead-${row.id}`}
                  className={styles.modal}
                  style={{ display: "none" }}
                >
                  <h1>Lead {row.id}</h1>
                  <div className={styles.ModalRowCell}>
                    <span>Nome:</span> {row.nome}
                  </div>
                  {page === "corretor" ? (
                    <div className={styles.ModalRowCell}>
                      <span>Corretor:</span> {corretor}
                    </div>
                  ) : (
                    <div className={styles.ModalRowCell}>
                      <span>Corretor:</span> {row?.user?.username}
                    </div>
                  )}
                  {page === "corretor" ? (
                    <div className={styles.ModalRowCell}>
                      <span>Corretora: </span> {corretora}
                    </div>
                  ) : (
                    <div className={styles.ModalRowCell}>
                      <span>Corretora: </span>
                      {corretoras &&
                        corretoras.map((corretora) =>
                          corretora.id == row?.user?.corretora
                            ? corretora.nome
                            : "",
                        )}
                    </div>
                  )}
                  {/* <div className={styles.ModalRowCell}>
                    <span>Entidade/Empresa: </span>
                    {entidades &&
                      entidades.map((entidade) =>
                        entidade.id == row?.id_entidade
                          ? entidade.descricao
                          : "",
                      )}
                  </div> */}
                  <div className={styles.ModalRowCell}>
                    <span>E-mail:</span> {row?.email}
                  </div>
                  <div className={styles.ModalRowCell}>
                    <span>Telefone:</span> {row?.telefone}
                  </div>
                  <div className={styles.ModalRowCell}>
                    <span>Preferência de contato:</span> {row?.pref_contato}
                  </div>
                  <div className={styles.ModalRowCell}>
                    <span>Criado:</span>{" "}
                    {row?.created_at.split("T")[0].split("-")[2]}/
                    {row?.created_at.split("T")[0].split("-")[1]}/
                    {row?.created_at.split("T")[0].split("-")[0]}
                  </div>
                  <div className={styles.ModalRowCell}>
                    <span>Atualizado:</span>{" "}
                    {row?.dt_mov.split("T")[0].split("-")[2]}/
                    {row?.dt_mov.split("T")[0].split("-")[1]}/
                    {row?.dt_mov.split("T")[0].split("-")[0]}
                  </div>
                  <div className={styles.ModalRowCell}>
                    <span>Status:</span>{" "}
                    {row?.status === "em_andamento"
                      ? "Em andamento"
                      : row?.status === "concluido"
                      ? "Concluído"
                      : row?.status === "pendente"
                      ? "Tentando Contato"
                      : row?.status === "finalizado"
                      ? "Finalizado"
                      : row?.status === "disponivel"
                      ? "Disponível"
                      : row?.status}
                  </div>
                  {row?.status === "finalizado" ? (
                    <div className={styles.ModalRowCell}>
                      <span>Motivo:</span> {row?.motivos}
                    </div>
                  ) : (
                    ""
                  )}
                  {row?.status !== "concluido" &&
                  row?.status !== "disponivel" ? (
                    <div className={styles.ModalRowCell}>
                      <span>Informações:</span> {row?.informacoes}
                    </div>
                  ) : (
                    ""
                  )}
                  {row?.status === "concluido" ? (
                    <div className={styles.ModalRowCell}>
                      <span>Contrato:</span> {row?.contrato}
                    </div>
                  ) : (
                    ""
                  )}
                  <CloseIcon
                    className={styles.fecharModal}
                    onClick={handleCloseModal}
                    id={row.id}
                  />
                </div>
                {/* MODAL - FINAL */}
              </div>
            ))}
        </section>
      </section>
    </>
  );
};

export default Lead;
